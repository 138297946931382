p{
    /* max-width: 500px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 20px;
    padding-right: 20px;
}
h4{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 10px;
        
}
body{
    background: rgba(236, 236, 236, 0.87);
    height: 100vh;
}
.leftbutton {
    position: fixed;
    bottom: 20px;
    /* Adjust the distance from the bottom as needed */
    right: 20px;
    /* Adjust the distance from the right as needed */
}

